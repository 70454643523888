import React, { FC, useEffect, useRef, useState } from 'react';
import Container from '../../components/ui/Container';
import Reg from '../../components/ui/Registration';
import Ellipse from '../../components/ui/Ellipses';
import styles from './styles.module.scss';
import Button from '../../components/ui/Buttons';
import { ISettings, ISettingsResponse } from '../../types/entity/Settings';
import { getSettings } from '../../utils/provider/admin/settings';
import TextInput from '../../components/input/TextInput';
import SelectInput from '../../components/input/SelectInput';
import { IOption } from '../../components/input/SelectInput/ISelectInput';
import { createParticipant } from '../../utils/provider/site/participant';
import { IErrorResponse, ISuccessResponse } from '../../types/SuccessResponse';
import classNames from 'classnames';

const RegistrationPage: FC = (): JSX.Element => {
  const [settings, setSettings] = useState<ISettings | null>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const [nameError, setNameError] = useState<string>('');
  const phoneRef = useRef<HTMLInputElement>(null);
  const [phoneError, setPhoneError] = useState<string>('');
  const emailRef = useRef<HTMLInputElement>(null);
  const [emailError, setEmailError] = useState<string>('');
  const brandRef = useRef<HTMLSelectElement>(null);
  const [brandError, setBrandError] = useState<string>('');
  const townRef = useRef<HTMLInputElement>(null);
  const [townError, setTownError] = useState<string>('');
  const salonRef = useRef<HTMLSelectElement>(null);
  const [salonError, setSalonError] = useState<string>('');
  const sellerRef = useRef<HTMLInputElement>(null);
  const [sellerError, setSellerError] = useState<string>('');
  const [brandOptions, setBrandOptions] = useState<IOption[]>([]);
  const [salonOptions, setSalonOptions] = useState<IOption[]>([]);
  const [registrationNumber, setRegistrationNumber] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);

  const validationName = (value: string): string => {
    let error = '';

    if (!value) {
      error = 'Это поле обязательно для заполнения';
    }

    return error;
  };

  const validationPhone = (value: string): string => {
    let error = '';

    if (!value) {
      error = 'Это поле обязательно для заполнения';
    }

    return error;
  };

  const validationEmail = (value: string): string => {
    let error = '';

    if (settings?.isEmail && settings.isEmailRequired && !value) {
      error = 'Это поле обязательно для заполнения';
    }

    return error;
  };

  const validationBrand = (value: string): string => {
    let error = '';

    if (settings?.isBrand && settings.isBrandRequired && !value) {
      error = 'Это поле обязательно для заполнения';
    }

    return error;
  };

  const validationTown = (value: string): string => {
    let error = '';

    if (settings?.isTown && settings.isTownRequired && !value) {
      error = 'Это поле обязательно для заполнения';
    }

    return error;
  };

  const validationSalon = (value: string): string => {
    let error = '';

    if (settings?.isSalon && settings.isSalonRequired && !value) {
      error = 'Это поле обязательно для заполнения';
    }

    return error;
  };

  const validationSeller = (value: string): string => {
    let error = '';

    if (settings?.isSeller && settings.isSellerRequired && !value) {
      error = 'Это поле обязательно для заполнения';
    }

    return error;
  };

  const resetForm = (): void => {
    if (nameRef.current) nameRef.current.value = '';
    if (phoneRef.current) phoneRef.current.value = '';
    if (emailRef.current) emailRef.current.value = '';
    if (brandRef.current) brandRef.current.value = '';
    if (townRef.current) townRef.current.value = '';
    if (salonRef.current) salonRef.current.value = '';
    if (sellerRef.current) sellerRef.current.value = '';

    setNameError('');
    setPhoneError('');
    setEmailError('');
    setBrandError('');
    setTownError('');
    setSalonError('');
    setSellerError('');
  };

  const onSubmitHandler = async (): Promise<void> => {
    let isValid = true;
    const nameValue = nameRef.current ? nameRef.current.value : '';
    const phoneValue = phoneRef.current ? phoneRef.current.value : '';
    const emailValue = emailRef.current ? emailRef.current.value : '';
    const brandValue = brandRef.current ? brandRef.current.value : '';
    const townValue = townRef.current ? townRef.current.value : '';
    const salonValue = salonRef.current ? salonRef.current.value : '';
    const sellerValue = sellerRef.current ? sellerRef.current.value : '';
    const nameErrorString = validationName(nameValue);
    const phoneErrorString = validationPhone(phoneValue);
    const emailErrorString = validationEmail(emailValue);
    const brandErrorString = validationBrand(brandValue);
    const townErrorString = validationTown(townValue);
    const salonErrorString = validationSalon(salonValue);
    const sellerErrorString = validationSeller(sellerValue);

    if (nameErrorString) {
      isValid = false;
      setNameError(nameErrorString);
    }
    if (phoneErrorString) {
      isValid = false;
      setPhoneError(phoneErrorString);
    }
    if (emailErrorString) {
      isValid = false;
      setEmailError(emailErrorString);
    }
    if (brandErrorString) {
      isValid = false;
      setBrandError(brandErrorString);
    }
    if (townErrorString) {
      isValid = false;
      setTownError(townErrorString);
    }
    if (salonErrorString) {
      isValid = false;
      setSalonError(salonErrorString);
    }
    if (sellerErrorString) {
      isValid = false;
      setSellerError(sellerErrorString);
    }

    if (isValid) {
      const participant = {
        name: nameValue,
        phone: phoneValue,
        email: emailValue,
        brand: brandValue,
        town: townValue,
        salon: salonValue,
        seller: sellerValue,
      };

      const { data } = await createParticipant<ISuccessResponse | IErrorResponse>(participant);

      if (data.result && 'data' in data) {
        setRegistrationNumber(data.data as number);
        setFormErrors([]);
        resetForm();
      } else {
        if ('errors' in data) setFormErrors([...data.errors]);
      }
    }
  };

  const modalCloseHandler = (): void => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getSettings<ISettingsResponse>()
      .then((response) => {
        setSettings(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (settings) {
      if (brandOptions.length === 0) {
        const options: IOption[] = [
          {
            title: `Бренд${settings.isBrandRequired ? ' *' : ''}`,
            value: '',
          },
        ];
        settings?.brands.map((brand) =>
          options.push({
            title: brand,
            value: brand,
          })
        );
        setBrandOptions(options);
      }
      if (salonOptions.length === 0) {
        const options: IOption[] = [
          {
            title: `Тип клиента${settings.isBrandRequired ? ' *' : ''}`,
            value: '',
          },
        ];
        settings?.salons.map((salon) =>
          options.push({
            title: salon,
            value: salon,
          })
        );
        setSalonOptions(options);
      }
    }
  }, [settings]);

  useEffect(() => {
    if (registrationNumber > 0) {
      setIsModalOpen(true);
    }
  }, [registrationNumber]);

  useEffect(() => {
    if (formErrors.length > 0) {
      setIsModalOpen(true);
    }
  }, [formErrors]);

  return (
    <section>
      <Container>
        <Reg>
          {settings?.mainHeader && (
            <div dangerouslySetInnerHTML={{ __html: settings?.mainHeader }} className={styles.title} />
          )}
          {settings?.formText && (
            <div dangerouslySetInnerHTML={{ __html: settings?.formText }} className={styles.title} />
          )}
          <div className={styles.registration__form}>
            <TextInput
              refElement={nameRef}
              id='name'
              label={''}
              type='text'
              value={''}
              onChangeValidation={validationName}
              error={nameError}
              placeholder='Имя и фамилия *'
            />
            <TextInput
              refElement={phoneRef}
              id='phone'
              label={''}
              type='tel'
              value={''}
              onChangeValidation={validationPhone}
              error={phoneError}
              placeholder='Телефон *'
            />
            {settings?.isEmail && (
              <TextInput
                refElement={emailRef}
                id='email'
                label={''}
                type='text'
                value={''}
                onChangeValidation={validationEmail}
                error={emailError}
                placeholder={`Почта${settings.isEmailRequired ? ' *' : ''}`}
              />
            )}
            {settings?.isBrand && (
              <SelectInput
                refElement={brandRef}
                id='brand'
                label=''
                options={brandOptions}
                value=''
                error={brandError}
                onChangeValidation={validationBrand}
              />
            )}
            {settings?.isTown && (
              <TextInput
                refElement={townRef}
                id='town'
                label={''}
                type='text'
                value={''}
                onChangeValidation={validationTown}
                error={townError}
                placeholder={`Город и метро ${settings.isTownRequired ? ' *' : ''}`}
              />
            )}
            {settings?.isSalon && (
              <SelectInput
                refElement={salonRef}
                id='salon'
                label=''
                options={salonOptions}
                value=''
                error={salonError}
                onChangeValidation={validationSalon}
              />
            )}
            {settings?.isSeller && (
              <TextInput
                refElement={sellerRef}
                id='seller'
                label={''}
                type='text'
                value={''}
                onChangeValidation={validationSeller}
                error={sellerError}
                placeholder={`ФИО продавца ${settings.isSellerRequired ? ' *' : ''}`}
              />
            )}

            <div className={styles.registration__button}>
              <Button nom='none' clickAction={onSubmitHandler}>
                {settings?.buttonText || 'Зарегистрироваться'}
              </Button>
            </div>
          </div>

          <div
            className={classNames(styles['modal'], {
              [styles['modal--show']]: isModalOpen,
            })}
          >
            <div className={styles['modal__wrapper']}>
              <div className={styles['modal__content']}>
                <div className={styles['modal__close']} onClick={modalCloseHandler} />
                <div className={styles['modal__data']}>
                  {formErrors.length > 0 ? (
                    <>
                      <p className={styles['modal__title']}>Ошибка!</p>
                      {formErrors.map((error, index) => (
                        <p className={styles['modal__text']} key={index}>
                          {error}
                        </p>
                      ))}
                    </>
                  ) : (
                    <>
                      <p className={styles['modal__title']}>Вы успешно зарегистрировались</p>
                      <p className={styles['modal__text']}>
                        Ваш порядковый номер <span className={styles['modal__number']}>{registrationNumber}</span>
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Reg>
      </Container>
      <Ellipse />
    </section>
  );
};

export default RegistrationPage;
